/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */
import DuplicatePatientModal from '@/components/patients/DuplicatePatientModal.vue'
import Sanitize from '@/mixins/Sanitize'
import { mdiAccountAlert, mdiAccountPlus, mdiSync } from '@mdi/js'
import themeConfig from '@themeConfig'
import { mapGetters } from 'vuex'

const duplicatePatientObj = ['dob', 'first_name', 'last_name', 'ssn4', 'signedEncounters', 'gender', 'place_of_service_id,']
const extractDupePatientProps = duplicatePatientObj => ({
  duplicatePatientObj,
})

export default {
  mixins: [Sanitize],
  data() {
    return {
      syncPos: 0,
      syncCnt: 0,
      encounter: {},
      batchSize: 25,
      icons: { mdiSync, mdiAccountAlert, mdiAccountPlus },
      syncErrMsg: false,
      conflictsArr: [],
    }
  },
  computed: {
    ...mapGetters('encounters', ['isSynced']),

    // ...mapState('encounters', { encounters: 'items' }),
    // ...mapState('patients', { patients: 'items' }),
    patientsToSync() {
      return this.$store.state.patients.items.filter(patient => (
        patient.updated
      ))
    },
    encountersToSync() {
      return this.$store.state.encounters.items.filter(encounter => (
        !encounter.is_synced
            && encounter.created_by_user_id === this.$authUser.userId()
      ))
    },
    commentsToSync() {
      return this.$store.state.comments.items.filter(comment => (
        (comment.new || comment.updated)
            && comment.created_by_user_id === this.$authUser.userId()
      ))
    },

    // syncCntTotaled() {
    //   return this.patientsToSync.length * 2 + this.encountersToSync.length + 3
    // },
  },
  mounted() {
  },
  methods: {
    // Initializes the sync.
    startSync(successRoute = false) {
      // Check first if authentication is valid
      return this.$store.dispatch('auth/refresh').then(() => {
        // Reset the sync progress values
        this.syncPos = 0

        // JMC: Changed to a more linear progress to decrease amount of times a mutation is performed.
        // syncCntTotaled should always be added to syncs that post
        this.syncCnt = 5

        // Record the start time
        const startTime = new Date()

        // Start the sync process
        this.syncingProgress()
        this.$store.dispatch('encounters/syncingModal', true)

        // Return the promise generated by syncAllPost
        return this.syncAllPost().then(syncAllPostSuccess => {
          if (syncAllPostSuccess === true) {
            // Return the promise generated by syncAllGet
            return this.syncAllGet().then(async syncAllGetSuccess => {
              if (syncAllGetSuccess === true) {
                // Clear sync counters to remove references
                this.syncPos = null
                this.syncCnt = null

                // Calculate the time taken
                const endTime = new Date()
                const timeTaken = (endTime - startTime) / 1000 // in seconds
                const minutes = Math.floor(timeTaken / 60)
                const seconds = Math.floor(timeTaken % 60)

                // Save to sync log
                const syncLogData = this.syncLogApiSetup('Success', 'Sync All', startTime, endTime, this.encountersToSync.length ? this.encountersToSync.length : 0, '')

                await this.$store.dispatch('syncLogPost', syncLogData)

                // Redirect user if a success route is provided
                if (successRoute) {
                  this.$router.push({ name: successRoute })
                }

                await this.$store.dispatch('auth/updateRecentlySyncedFacilities')

                // Display the success dialog with time information
                const successMessage = `Sync completed successfully in ${minutes} minutes and ${seconds} seconds.`
                await this.successDialog(successMessage) // No need to wait for user interaction before continuing
                window.location.reload()
              } else {
                throw syncAllGetSuccess
              }
            })
              .catch(error => {
                const syncLogData = this.syncLogApiSetup('Error', 'Sync All', startTime, new Date(), this.encountersToSync.length ? this.encountersToSync.length : 0, error)
                this.$store.dispatch('syncLogPost', syncLogData)
                this.errorDialog(error)
              })
          }
        })
      }).catch(error => {
        // Error refreshing session, this is due to an invalid refresh token, happens when the user logged in to another browser or device.
        const errorMessage = `Invalid token detected, please log in again.<br>Error: ${error}`

        this.sessionExpiredDialog(errorMessage) // No need to wait for user interaction before continuing
      })
    },

    syncAllEncounters(successRoute = false) {
      let syncMessage = 'Are you sure you wish to sync all encounters, surgical notes, comments, and patients?'

      const syncReminders = `
          <br><hr><br>
          <p style="color: red; text-decoration: underline; margin-bottom: 5px;">
              <strong>Reminders:</strong>
          </p>
          <ul style="list-style-type: none; padding: 0; margin: 0;">
              <li style="position: relative; padding: 5px 0; margin-bottom: 5px; font-size: 0.9em;">
                  Only data for patients at facilities listed under "Your Facilities" in the
                  Facility Management section will be downloaded or synchronized with Skilled Wound Care.
                  <span style="position: absolute; left: 0; bottom: -5px; width: 100%; text-align: center; font-size: 0.8em; color: #ccc;">
                      --
                  </span>
              </li>
              <li style="position: relative; padding: 5px 0; margin-bottom: 5px; font-size: 0.9em;">
                  Only the newest few notes per patient are downloaded to your device,
                  regardless of the originating provider. Any unsigned notes will always
                  be available in addition to those.
                  <span style="position: absolute; left: 0; bottom: -5px; width: 100%; text-align: center; font-size: 0.8em; color: #ccc;">
                      --
                  </span>
              </li>
              <li style="position: relative; padding: 5px 0; font-size: 0.9em;">
                  In general, it is better to sync with Wi-Fi turned off,
                  which causes the iPad to use its built-in mobile data network.
                  If you experience consistently slow syncs or frequent sync errors,
                  turn off the Wi-Fi on your iPad to switch to mobile data.
              </li>
          </ul>
      `

      syncMessage += `<br>${syncReminders}`

      this.$root.confirm({
        title: 'Sync All Encounters, Patients, and Comments?',
        subTitle: 'Warning: This operation cannot be undone!',
        body: syncMessage,
        html: true,
        confirm: 'Sync Data',
        confirmIcon: this.icons.mdiSync,
        confirmColor: 'error',
      }).then(result => {
        if (result === true) {
          this.startSync(successRoute)
        }
      })
    },

    syncingProgress() {
      this.$store.dispatch('encounters/syncingProgress', { pos: this.syncPos, cnt: this.syncCnt })
      this.syncPos += 1
    },

    sessionExpiredDialog(messageBody) {
      this.$root.confirm({
        titleIconColor: 'error',
        title: 'Session Expired',
        body: messageBody,
        html: true,
        cancel: false,
        persistent: true,
      })
    },

    async successDialog(messageBody) {
      console.log('---------- Sync Completed Successfully ----------')
      this.$store.commit('encounters/synced', true)
      this.$store.dispatch('encounters/syncingModal', false)
      await this.$root.confirm({
        titleIconColor: 'success',
        title: 'Sync Successful!',
        body: messageBody,
        html: true,
        cancel: false,
        persistent: true,
        confirmIcon: this.icons.mdiSync,
        confirm: 'Reload',
      })
    },

    async errorDialog(response, defaultMessage) {
      console.log('---------- Sync Completed with Errors ----------')
      this.$store.commit('encounters/synced', false)
      this.$store.dispatch('encounters/syncingModal', false)
      const syncLogData = this.syncLogApiSetup('Error', 'Sync All', new Date(), new Date(), this.encountersToSync.length ? this.encountersToSync.length : 0, response)
      this.$store.dispatch('syncLogPost', syncLogData)

      await this.$root.confirm({
        titleIconColor: 'error',
        title: 'Sync Error!',
        body: response === false || this.$custom.isEmpty(response) ? defaultMessage : response,
        html: true,
        cancel: false,
      })
    },

    async syncAllPost() {
      console.log('---------- Starting Post Sync Sequence ----------')
      this.syncErrMsg = ''

      return new Promise(next => {
        console.log('Start Core Sync')
        this.syncCorePatients().then(patientCoreSuccess => {
          console.log('Finish Core Sync')
          this.syncingProgress()
          if (patientCoreSuccess !== true) {
            this.syncErrMsg += patientCoreSuccess
            this.syncErrMsg += '<hr><br>'
          }
          console.log('Start Encounter Sync')
          this.syncEncounters().then(encounterSuccess => {
            console.log('Finish Encounter Sync')
            this.syncingProgress()
            if (encounterSuccess !== true) {
              this.syncErrMsg += encounterSuccess
              this.syncErrMsg += '<hr><br>'
            }
            console.log('Start Patient Full Sync')
            this.syncFullPatients().then(patientFullSuccess => {
              console.log(patientFullSuccess)
              console.log('End Patient Full Sync')
              this.syncingProgress()
              if (patientFullSuccess !== true) {
                this.syncErrMsg += patientFullSuccess
                this.syncErrMsg += '<hr><br>'
              }
              this.syncComments().then(async commentSuccess => {
                if (commentSuccess !== true) {
                  this.syncErrMsg += commentSuccess
                  this.syncErrMsg += '<hr><br>'
                }

                // Handle if there's a message from the different syncs
                if (this.syncErrMsg) {
                  if (this.syncErrMsg.toLowerCase().includes('server is busy')) {
                    const serverBusyMsg = `The server is busy, please try syncing again later.<br><br>
                    <p style="color: red;">
                    <strong>DO NOT ATTEMPT TO RESET THE LOCAL STORAGE AS IT WILL NOT RESOLVE THIS ISSUE AND WILL ONLY DELETE UNSYNCED RECORDS!</strong>
                    </p>`
                    this.syncErrMsg += serverBusyMsg
                  }
                  await this.errorDialog(this.syncErrMsg)
                  const targetRoute = { name: 'home' }

                  // Navigate to the same route but with a unique query parameter (current time)
                  this.$router.replace({ ...targetRoute, query: { timestamp: new Date().getTime() } })
                }
                console.log('---------- Finished Post Sync Sequence ----------')
                next(!this.syncErrMsg)

                // else {
              //   this.errorDialog(commentSuccess, 'One or more comments failed to sync!')
              // }
              })
                .catch(error => {
                  this.errorDialog(error)
                })

              // else {
              //   this.errorDialog(patientFullSuccess, 'One or more patients failed to sync!')
              // }
            })
              .catch(error => {
                this.errorDialog(error)
              })

            // else {
            //   this.errorDialog(encounterSuccess, 'One or more encounters failed to sync!')
            // }
          })
            .catch(error => {
              this.errorDialog(error)
            })

          // else {
          //  this.errorDialog(patientCoreSuccess, 'One or more patients failed to sync!')
          // }
        })
          .catch(error => {
            this.errorDialog(error)
          })
      })
    },

    async syncAllGet() {
      console.log('---------- Starting Get Sync Sequence ----------')

      return new Promise((next, reject) => {
        this.$store.dispatch('syncAll', { pos: this.syncPos - 1, cnt: this.syncCnt }).then(syncAllSuccess => {
          if (typeof syncAllSuccess === 'string') reject(syncAllSuccess)

          console.log('Refreshing user data...')
          this.$store.dispatch('auth/fetch').then(fetchUserSuccess => {
            this.$store.dispatch('encounters/syncingModal', false)
            console.log('Done refreshing user data')
            if (fetchUserSuccess?.statusText === 'OK' && syncAllSuccess === true) {
              // this.$store.dispatch('notify', { value: 'Sync all data successful.', color: 'success' })
              next(true)
            } else {
              // Check if there is an error message
              const errorMessage = typeof syncAllSuccess !== 'boolean' ? syncAllSuccess : 'Unknown communication error'

              // Show an error dialog
              const statusErrorMessage = fetchUserSuccess?.statusText !== 'OK' ? `Error: ${fetchUserSuccess.statusText}` : ''

              this.errorDialog(`${errorMessage}<br>${statusErrorMessage}`, 'One or more items failed to sync!')

              // Reject the promise to indicate failure
              reject(new Error('One or more items failed to sync!'))
            }
          })
            .catch(error => {
              reject(error.message)
            })
        })
          .catch(error => {
            reject(error.message)
          })
      })
    },

    async syncEncounters(initialEncounters = this.encountersToSync) {
      // Loop through encounters
      let encounterErrorsDisplay = ''
      let responseEncounters = 'Encounters Sync failed'
      let errorEncounters = 0
      let iterator = 0
      let alternativeMessage = ''
      const encountersToUpdate = []

      // Remove encounter records where the patient record has a conflict
      const encountersToSync = initialEncounters.filter(patientRecord => !this.conflictsArr.some(conflict => conflict.id === patientRecord.id))

      console.log('Encounters to post:', encountersToSync.length)
      if (encountersToSync.length > 0 && !encountersToSync[0]?.sync_log) {
        encountersToSync[0].sync_log = this.syncLogSetup('Main: Encounters')
      }
      for (let i = 0; i < encountersToSync.length; i += this.batchSize) {
        iterator += 1
        const encounterBatch = []
        console.log(`Batch number: ${iterator}`)
        const chunk = encountersToSync.slice(i, i + this.batchSize)
        chunk.forEach(e => {
          this.encounter = {
            ...this.$lodash.cloneDeep(e),
          }
          this.encounter.is_synced = true
          this.sanitizeEncounter()

          encounterBatch.push(this.encounter)
        })

        if (i === 0 && (encounterBatch.length > 0 && !encounterBatch[0]?.sync_log)) {
          encounterBatch[0].sync_log = this.syncLogSetup('Main: Encounters')
        }

        this.$store.commit('encounters/SET_SYNCING_TXT', `Uploading encounters: batch ${iterator} of ${Math.ceil(encountersToSync.length / this.batchSize)}`)
        await new Promise((next, reject) => {
          this.$store.dispatch('encounters/syncEncounter', { data: encounterBatch, retries: 3 })
            .then(response => {
              const { responseData } = response
              // For when we caught an error in the API response, we actually get a string as a response formatted by $custom.processCommError method
              if (typeof response === 'string' || !Array.isArray(responseData)) {
                if (typeof response === 'string' && response.toLowerCase().includes('network error')) {
                  response += '<br><br>Please check your internet connection and try to sync again. If the problem persists, contact IT Support.'
                }

                reject(response)
              }

              responseData.forEach(encounterResponse => {
                const encounterData = encounterBatch.find(element => element.id === encounterResponse.encounterID)

                if (encounterResponse.status === 'Success') {
                  // Any new attachments were synced successfully, remove updated flag
                  // this.encounter.encounter_attachments?.forEach((item, i) => {
                  //   delete this.encounter.encounter_attachments[i].updated
                  // })
                  encountersToUpdate.push({ encounter: encounterData })
                } else {
                  encounterData.is_synced = false
                  encountersToUpdate.push({ encounter: encounterData })
                  errorEncounters += 1

                  // On table lock, user won't be able to sync
                  if (encounterResponse.message.toLowerCase().includes('1205 lock')) {
                    alternativeMessage = '<strong>Reason:</strong> Server is busy<br>'
                  } else {
                    // This currently implemented show a more verbose response, later on we don't want to actually show this.
                    alternativeMessage = `<strong>Reason:</strong> ${encounterResponse.message}<br>`
                  }

                  // For displaying purposes, only three failed encounters are shown.
                  if (errorEncounters < 4) {
                    encounterErrorsDisplay += `${encounterErrorsDisplay ? '--<br>' : ''}
                    <strong>Encounter Id:</strong> ${encounterData.id}<br>
                    <strong>Patient:</strong> ${encounterData.first_name} ${encounterData.last_name}<br>
                    <strong>Date Of Service:</strong> ${this.$date(encounterData.created).format('MM/DD/YYYY HH:mm')}<br>
                    ${alternativeMessage}`
                  }
                }
                next()
              })
            })
        })
      }
      this.$store.commit('encounters/updateEncounter', encountersToUpdate)

      if (errorEncounters === 0) console.log('Done posting encounters without errors!')

      responseEncounters += `
      <p style="font-size: 11px;">
      ${errorEncounters} ${errorEncounters === 1 ? 'encounter' : 'encounters'} failed to sync.<br>
      ${errorEncounters < 4 ? 'List of encounter records that failed:<br>' : 'Below are the first three encounter records that failed:<br>'}
      ${encounterErrorsDisplay}</p>`

      // Checks if alternativeMessage is present to display it instead of the failed records.

      return errorEncounters === 0 ? true : responseEncounters
    },

    async syncComments(commentsToSync = this.commentsToSync) {
      let commentsErrorsDisplay = ''
      console.log('Comments to Post: ', commentsToSync.filter(c => c.new).length)
      console.log('Comments to Update: ', commentsToSync.filter(c => c.updated).length)

      // Create two separate objects for posting new and updated comments
      const commentsToPost = commentsToSync.filter(c => c.new)
        .map(comment => ({
          ...this.$lodash.cloneDeep(comment),
          new: undefined
        })) // Remove new flag and create new object
      const commentsToUpdate = commentsToSync.filter(c => c.updated)
        .map(comment => ({
          id: comment.id,
          is_read: comment.is_read,
          updated: undefined
        }))

      if (commentsToPost.length > 0) {
        if (commentsToPost[0]?.sync_log) {
          commentsToPost[0].sync_log.syncType = 'Main: Post Comments Core'
        } else {
          commentsToPost[0].sync_log = this.syncLogSetup('Main: Post Comments Core')
        }
        const postPromRes = await this.$store.dispatch('comments/postComment', commentsToPost)

        if (postPromRes) {
          commentsToPost.forEach(comment => {
            delete comment.new
            this.$store.commit('comments/updateCommentSync', comment)
            console.log(`Comment posted: ${comment.id}`)
          })
        } else {
          const errors = commentsToPost.map(comment => `comment error (post): ${comment.id} - ${postPromRes && postPromRes.message ? postPromRes.message : 'Failed!'}`)
          commentsErrorsDisplay += errors.join('<br><br>')
          console.log(errors)
        }
      }

      if (commentsToUpdate.length > 0) {
        commentsToUpdate[0].sync_log = this.syncLogSetup('Main: Update Comments Core')

        const updatePromRes = await this.$store.dispatch('comments/syncComment', commentsToUpdate)

        if (updatePromRes) {
          commentsToUpdate.forEach(comment => {
            delete comment.updated
            this.$store.commit('comments/updateCommentSync', comment)
            console.log(`Comment updated: ${comment.id}`)
          })
        } else {
          const errors = commentsToUpdate.map(comment => `comment error (update): ${comment.id} - ${updatePromRes && updatePromRes.message ? updatePromRes.message : 'Failed!'}`)
          commentsErrorsDisplay += errors.join('<br><br>')
          console.log(errors)
        }
      }

      if (commentsToSync.length) console.log('Done posting/updating comments')

      return commentsErrorsDisplay.length === 0 ? true : commentsErrorsDisplay
    },

    async syncCorePatients(patientsToSync = this.patientsToSync) {
      // Loop through patients
      let patientErrorsDisplay = ''
      let responsePatients = 'Core Patient Sync failed'
      let errorPatients = 0
      let iterator = 0
      let alternativeMessage = ''

      if (patientsToSync.length > 0 && !patientsToSync[0]?.sync_log) {
        patientsToSync[0].sync_log = this.syncLogSetup('Main: Patients Core')
      }

      console.log('Patients to post:', patientsToSync.length)
      for (let i = 0; i < patientsToSync.length; i += this.batchSize) {
        iterator += 1
        const patientBatch = []
        console.log(`Batch number: ${iterator}`)
        const chunk = patientsToSync.slice(i, i + this.batchSize)
        chunk.forEach(p => {
          const patient = p
          delete patient.encounters
          patient.force_add = true // Force add the patient, even if a duplicate is detected
          patientBatch.push(patient)
        })

        this.$store.commit('encounters/SET_SYNCING_TXT', `Uploading core patients: batch ${iterator} of ${Math.ceil(patientsToSync.length / this.batchSize)}`)
        await new Promise((next, reject) => {
          this.$store.dispatch('patients/syncPatient', { data: patientBatch, retries: 3 })
            .then(response => {
              const { responseData } = response
              // For when we caught an error in the API response, we actually get a string as a response formatted by $custom.processCommError method
              if (typeof response === 'string' || !Array.isArray(responseData)) {
                if (typeof response === 'string' && response.toLowerCase().includes('network error')) {
                  response += '<br><br>Please check your internet connection and try to sync again. If the problem persists, contact IT Support.'
                }
                reject(response)
              } else {
                responseData.forEach(patientResponse => {
                  const patientData = this.$store.getters['patients/getById'](patientResponse.patientID)
                  if (patientResponse.status === 'Success') {
                    // We haven't actually synced the entire patient record at this point,
                    // so we shouldn't mark the record as synced.
                    // delete patient.updated
                    // this.$store.commit('patients/updatePatientSync', patient)

                    // console.log('Patient post successful:')
                    delete patientData.updated_core
                  } else {
                    // If duplicate record
                    errorPatients += 1

                    if (patientResponse.message.toLowerCase().includes('duplicate')) {
                      console.log(extractDupePatientProps(patientResponse))
                      this.conflictsArr.push(
                        [
                          {
                            originatingPatient: response.originalRequestData.find(requestData => requestData.id === patientResponse.patientID),
                            dupPatients: patientResponse.dupPatient,
                          },
                        ],
                      )
                    }
                    if (patientResponse.message.toLowerCase().includes('1205 lock')) {
                      alternativeMessage = '<strong>Reason:</strong> Server is busy<br>'
                    } else {
                      // This currently implemented show a more verbose response, later on we don't want to actually show this.
                      alternativeMessage = `<strong>Reason:</strong> ${patientResponse.message}<br>`
                    }

                    // For displaying purposes, only three failed patients are shown.
                    if (errorPatients < 4) {
                      patientErrorsDisplay += `${patientErrorsDisplay ? '--<br>' : ''}
                    <strong>Name:</strong> ${patientData.first_name} ${patientData.last_name}<br>
                    <strong>DOB:</strong> ${this.$date(patientData.dob).format('MM/DD/YYYY')}<br>
                    ${alternativeMessage}`
                    }
                  }
                })
              }
              next()
            })
        })
      }
      if (errorPatients === 0) console.log('Done posting patients without errors!')

      responsePatients += `<p style="font-size: 11px;">
        ${errorPatients} patient
        ${errorPatients === 1 ? 'record' : 'records'} failed to sync.<br>
        ${errorPatients < 4 ? 'List of patient records that failed:<br>' : 'Below are the first three patient records that failed:<br>'}
        ${patientErrorsDisplay}</p>`

      return errorPatients === 0 ? true : responsePatients
    },

    async syncFullPatients(initialPatients = this.patientsToSync) {
      let patientErrorsDisplay = ''
      let errorPatients = 0
      let responsePatients = 'Full Patient Sync failed'
      let alternativeMessage = ''
      const patientsToUpdate = []

      // Remove conflicting patient records
      const patientsToSync = initialPatients.filter(patientRecord => !this.conflictsArr.some(conflict => conflict.id === patientRecord.id))

      if (patientsToSync.length > 0 && !patientsToSync[0]?.sync_log) {
        patientsToSync[0].sync_log = this.syncLogSetup('Main: Full Patients')
      }

      console.log('Patient w/wounds to post:', patientsToSync.length)
      let iterator = 0

      // Loop through patients
      for (let i = 0; i < patientsToSync.length; i += this.batchSize) {
        iterator += 1
        const patientBatch = []
        console.log(`Batch number: ${iterator}`)
        const chunk = patientsToSync.slice(i, i + this.batchSize)
        chunk.forEach(p => {
          const patient = p
          delete patient.encounters
          patientBatch.push(patient)
        })

        this.$store.commit('encounters/SET_SYNCING_TXT', `Uploading full patients: batch ${iterator} of ${Math.ceil(patientsToSync.length / this.batchSize)}`)
        await new Promise((next, reject) => {
          this.$store.dispatch('patients/syncFullPatient', { data: patientBatch, retries: 3 })
            .then(response => {
              const { responseData } = response
              // For when we caught an error in the API response, we actually get a string as a response formatted by $custom.processCommError method
              if (typeof response === 'string' || !Array.isArray(responseData)) {
                if (typeof response === 'string' && response.toLowerCase().includes('network error')) {
                  response += '<br><br>Please check your internet connection and try to sync again. If the problem persists, contact IT Support.'
                }
                reject(response)
              } else {
                // Handle expected behavior and iterate over patient to see sync status individually
                responseData.forEach(patientResponse => {
                  const patientData = this.$store.getters['patients/getById'](patientResponse.patientID)

                  if (patientResponse.status === 'Success') {
                    // Any new attachments were synced successfully, remove updated flag
                    patientData.all_attachments?.forEach(attachment => {
                      // eslint-disable-next-line no-param-reassign
                      delete attachment.updated
                    })

                    // Temporary solution until wounds/treatments can be properly deleted
                    // Delete Wounds/Treatments can_be_deleted, updated flags to prevent "deletion" after successful sync
                    patientData.wounds?.forEach(wound => {
                      // eslint-disable-next-line no-param-reassign
                      delete wound.can_be_deleted
                      delete wound.updated
                      wound.treatments?.forEach(treatment => {
                        // eslint-disable-next-line no-param-reassign
                        delete treatment.can_be_deleted
                        delete treatment.updated
                      })
                    })

                    // Mark the patient as synced by removing "updated" property.
                    delete patientData.updated
                    patientsToUpdate.push(patientData)

                    // console.log('Patient w/wounds post successful:', patient.id)
                  } else {
                    errorPatients += 1

                    // We no longer need this since we're only marking the patient as synced within this function.
                    // Patient sync failed, set 'updated' back to true as we still need to sync this patient
                    // this.$store.commit('patients/updatePatient', patient)
                    if (patientResponse.message.toLowerCase().includes('1205 lock')) {
                      alternativeMessage = '<strong>Reason:</strong> Server is busy<br>'
                    } else {
                      // This currently implemented show a more verbose response, later on we don't want to actually show this.
                      alternativeMessage = `<strong>Reason:</strong> ${patientResponse.message}<br>`
                    }

                    // For displaying purposes, only three failed patients are shown.
                    if (errorPatients < 4) {
                      patientErrorsDisplay += `${patientErrorsDisplay ? '--<br>' : ''}
                    <strong>Name:</strong> ${patientData.first_name} ${patientData.last_name}
                    <strong><br>DOB:</strong> ${this.$date(patientData.dob).format('MM/DD/YYYY')}<br>
                    ${alternativeMessage}`
                    }
                  }
                })
              }
              next()
            })
        })
      }

      if (errorPatients === 0) console.log('Done posting patient w/wounds without errors!')

      responsePatients += `<p style="font-size: 11px;">
        ${errorPatients} patient
        ${errorPatients === 1 ? 'record' : 'records'} failed to sync.<br>
        ${errorPatients < 4 ? 'List of patient records that failed:<br>' : 'Below are the first three patient records that failed:<br>'}
        ${patientErrorsDisplay}</p>`

      this.$store.commit('patients/updatePatientSync', patientsToUpdate)

      return errorPatients === 0 ? true : responsePatients
    },

    // Functions used for handling duplicate patients

    duplicatePatientsMap(duplicatePatients) {
      const duplicatePatientList = []
      duplicatePatients.forEach(patient => {
        const facility = this.$store
          .getters['facilities/getById'](patient.place_of_service_id)?.title

        const mappedPatientData = {
          name: `${patient.first_name || ''} ${patient.middle_initial || ''}
            ${patient.last_name || ''} ${patient.suffix || ''}`,
          date_of_birth: this.$date(patient.dob).format('MM/DD/YYYY'),
          gender: patient.gender,
          ssn_last_4: patient.ssn_last_4,
          facility,
        }

        duplicatePatientList.push({
          id: patient.id,
          title: `${mappedPatientData.facility}`,
          ...mappedPatientData,
          originalValue: patient,
        })
      })

      return duplicatePatientList
    },

    async duplicatePatientConfirmation(entered, duplicatePatients) {
      let useExisting = true
      await new Promise(next => {
        this.$store.dispatch('encounters/syncingModal', false)
        this.$root.confirm({
          title: 'Duplicate Patient Found!',
          subTitle: `A patient by the name of ${entered.first_name} ${entered.last_name} already exists on your database.
            Do you want to add as a new patient or use the already existing patient from database?`,
          body: {
            enteredPatient: entered,
            duplicatePatients: this.duplicatePatientsMap(duplicatePatients),
          },
          isDuplicateConfirmation: true,
          html: false,
          large: true,
          cancel: 'Use already existing patient',
          cancelIcon: this.icons.mdiAccountPlus,
          cancelColor: 'primary',
          confirm: 'Add as new patient',
          confirmIcon: this.icons.mdiAccountPlus,
          passedComponent: DuplicatePatientModal,
        }).then(response => {
          useExisting = response
          next()
        })
      })

      return useExisting
    },

    resolveDuplicatePatientRecord(existingRecord) {
      // Check first if the patient already exists in the store.
      if (this.isPatientIdInStore(existingRecord.id)) {
        // Just return the id if the patient already exists in the store.
        return existingRecord.id
      }
      const adjustedPatient = { ...existingRecord }

      // Checks to see if encounters already exist in VueX, add if they don't already exist.
      const encountersToAdd = []
      existingRecord.encounters.forEach(encounter => {
        if (this.$store.getters['encounters/getIndexById'](encounter.id) === -1) {
          // console.log(encounter)
          encountersToAdd.push(encounter)
        }
      })
      if (encountersToAdd.length >= 1) {
        this.$store.commit('encounters/addEncounters', encountersToAdd)
      }

      // Delete items that do not belong in VueX patients object structure
      delete adjustedPatient.encounters
      delete adjustedPatient.signedEncountersCount

      // Change facility of existing patient record to the facility of the local patient record.
      adjustedPatient.place_of_service_id = this.patient.place_of_service_id

      // Add patient and navigate to patient profile
      this.$store.commit('patients/addPatient', adjustedPatient)

      return adjustedPatient.id
    },
    syncLogSetup(syncType) {
      const { version } = themeConfig.app
      let startTime = new Date()
      startTime = startTime.toISOString()
      startTime = startTime.replace('T', ' ')
      startTime = startTime.slice(0, startTime.indexOf('.'))

      return {
        version, syncType, startTime,
      }
    },
    syncLogApiSetup(stat, syncType, start, end, noteNum, errors) {
      const status = stat === 'Success' ? 'Success' : 'Error'
      const userId = this.$authUser.userId()
      const { version } = themeConfig.app
      const details = ''

      let startTime = start.toISOString()
      startTime = startTime.replace('T', ' ')
      startTime = startTime.slice(0, startTime.indexOf('.'))

      let endTime = end.toISOString()
      endTime = endTime.replace('T', ' ')
      endTime = endTime.slice(0, endTime.indexOf('.'))

      return {
        userId, version, status, syncType, startTime, endTime, noteNum, errors, details,
      }
    },
    isPatientIdInStore(patientId) {
      // Check if the patient ID exists in the store
      return this.$store.state.patients.items.some(patient => patient.id === patientId)
    },
    isObject(value) {
      return typeof value === 'object' && value !== null && !Array.isArray(value)
    },
  },
}
